import { SerializableEvent } from '@/models/events/SerializableEvent'
import { cloneDeep } from 'lodash'
import Rest from '../Rest'

export default class QuestionnaireAnswerService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/questionnaire_answer'

  static build(vs: any = null, baseUrl) {
    return new this(this.resource, null, vs, baseUrl)
  }

  answer(id: Number, fieldId: Number, params: any) {
    return this.post(`/${id}/answer/${fieldId}`, params).then(data => {
      return data
    })
  }

  passwordValidate(id: Number, password: string) {
    const params = {
      password: password
    }
    return this.post(`/${id}/password_validate`, params).then(data => {
      return data
    })
  }

  nextQuestion(id: Number, questionId: string) {
    return this.post(`/${id}/question/${questionId}/next`).then(
      data => data
    )
  }

  previousQuestion(id: Number, questionId: string) {
    return this.post(`/${id}/question/${questionId}/previous`).then(
      data => data
    )
  }

  start(id: Number, password: string) {
    const params = {
      password: password
    }

    return this.post(
      `/content_questionnaire/${id}/start`,
      params
    ).then(data => {
      return data
    })
  }

  startPracticalTest(practicalTestStudentId: Number, sectionId: String) {
    return this.post(
      `/content_questionnaire/${practicalTestStudentId}/start_practical_test/${sectionId}`
    ).then(data => {
      return data
    })
  }

  finish(id: Number, data: any) {
    return this.post(`/${id}/finish`, data).then(data => {
      return data
    })
  }

  finishPracticalTest(id: Number, sectionId: string, practicalTestStudentId: number) {

    const params = {
      'section_id': sectionId,
      'practical_test_student_id': practicalTestStudentId
    }

    return this.post(`/${id}/finish`, params).then(data => {
      return data
    })
  }

  answersQuestion(id: Number, grid = {}) {
    return this.get(`/${id}/answers/question`, grid).then(data => {
      return data
    })
  }

  answersUser(id: Number, grid = {}) {
    return this.get(`/${id}/answers/user`, grid).then(data => {
      return data
    })
  }

  allResults(shortUrlId: String) {
    return this.get(`/${shortUrlId}/all_results`).then(data => {
      return data
    })
  }

  results(shortUrlId: String, $answerId: Number) {
    return this.get(`/${shortUrlId}/${$answerId}/results`).then(data => {
      return data
    })
  }

  correct(id: Number, questionId: String, correction: String, group: String = 'question') {
    const params = {
      correction: correction,
      group: group
    }

    return this.post(`/${id}/question/${questionId}/correct`, params).then(
      data => {
        return data
      }
    )
  }

  score(id: Number, questionId: String, score: String) {
    const params = {
      score: score
    }

    return this.post(`/${id}/question/${questionId}/score`, params).then(
      data => {
        return data
      }
    )
  }

  comment(answerId: Number, questionUUID: String, data: JSON) {
    const params = {
      question_uuid: questionUUID,
      data: data
    }
    return this.post(`/${answerId}/comment`, params).then(
      response => {
        return response
      }
    )
  }

  findCommentsByAnswer(answerId: Number, questionUUID: String) {
    return this.get(`/${answerId}/${questionUUID}/comments`).then(
      data => {
        return data
      }
    )
  }

  reset(id: Number) {
    return this.post(`/${id}/reset`).then(
      data => {
        return data
      }
    )
  }

  resetToBlank(id: Number) {
    return this.post(`/${id}/reset_blank`).then(
      data => {
        return data
      }
    )
  }

  secondChanceAnswerUser(id: Number) {
    return this.post(`/${id}/second_chance`).then(
      data => {
        return data
      }
    )
  }

  individualResume(questionnaireId: Number) {
    return this.get(`/${questionnaireId}/resume/individual`).then(data => {
      return data
    })
  }

  registerEvent(answer_id: number, event: SerializableEvent) {
    return this.post(`/${answer_id}/event`, event.serialize()).then(
      data => data
    )
  }

  getEvents(answer_id: number) {
    return this.get(`/${answer_id}/events`).then(
      data => data
    )
  }

  bookmarkQuestion(answer_id, question_id) {
    return this.post(`/${answer_id}/question/${question_id}/bookmark`)
  }

  removeQuestionBookmark(answer_id, question_id) {
    return this.delete(`/${answer_id}/question/${question_id}/bookmark`)
  }

  checkQuestionAnswer(questionnaireId: Number, questionId: String, params) {
    return this.post(`check_question_answer/questionnaire/${questionnaireId}/q/${questionId}`, params).then(data => {
      return data
    })
  }

  sendReconcileAnswers(answer_id, answers) {
    return this.post(`/${answer_id}/save_reconcile_data`, answers)
  }
}
